import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import { getIDeviceComponent } from '@ucheba/utils/helpers/components'
import { IObjectDevicesComponents } from '@ucheba/utils/helpers/components/types'
import React from 'react'
import { NextSeo } from 'next-seo'
import { locationSelectors } from '@ucheba/store/location'
import { useSelector } from 'react-redux'
import { IIndexPageProps } from './types'
import data from './data.json'

const devicesPages: IObjectDevicesComponents = {
  desktop: dynamic(() => import('./desktop')),
  touch: dynamic(() => import('./touch')),
}

const IndexPage: NextPage<IIndexPageProps> = (props) => {
  const defaultLocations = useSelector(locationSelectors.locations)

  const content = {
    ...data,
    chooseLocationDialogContent: {
      ...data.chooseLocationDialogContent,
      defaultLocations,
    },
  }

  return (
    <>
      <NextSeo
        title={content.seo.meta.title}
        description={content.seo.meta.description}
        openGraph={{
          images: [
            {
              url: 'https://www.ucheba.ru/pix/upload/default/img__og-image_index-page_new.png',
              width: 1200,
              height: 630,
            },
          ],
        }}
      />

      {getIDeviceComponent({ ...props, content }, devicesPages)}
    </>
  )
}

export default IndexPage
