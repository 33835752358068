import { NextPageContext } from 'next'
import { tagsStatsThunks } from '@ucheba/store/tags/stats'
import { EProgramTypes } from '@ucheba/utils/types/program'
import { locationThunks } from '@ucheba/store/location'
import { institutionsRankingsStatsThunks } from '@ucheba/store/institutions/rankings/stats'
import { eventsSearchThunks } from '@ucheba/store/events/search'
import { eventsMyThunks } from '@ucheba/store/events/my'
import { articlesThunks } from '@ucheba/store/articles'
import { authSelectors } from '@ucheba/store/auth'
import IndexEgePage from './index'
import { headerActions } from '../components/Header/store'
import { footerActions } from '../components/Footer/store'
import { sectionsEventsNavigationItems } from './for-abiturients/events/index/constants'

IndexEgePage.getInitialProps = async (ctx: NextPageContext & { store: any }) => {
  const { store } = ctx
  const { dispatch } = store

  // programTypes для мероприятий на главной
  const programTypes = sectionsEventsNavigationItems
    .filter((_, key) => key !== 0)
    .map((el) => el.slug)
  const isAuth = authSelectors.isAuth(store.getState())

  await Promise.all([
    dispatch(articlesThunks.fetch({ ctx, data: { limit: 10, isMain: true } })),
    dispatch(
      tagsStatsThunks.fetchParents({
        ctx,
        data: {
          programTypes: [EProgramTypes.higher],
        },
      })
    ),
    dispatch(locationThunks.fetch({ ctx, data: { isMain: 1, limit: 100 } })),
    dispatch(institutionsRankingsStatsThunks.fetch({ ctx })),
    dispatch(
      eventsSearchThunks.fetch({ ctx, data: { limit: 5, programTypes, isFinished: 0 } })
    ),
    isAuth && dispatch(eventsMyThunks.fetch({ ctx })),
  ])

  dispatch(headerActions.setColor('white'))
  dispatch(headerActions.setFade('navy'))
  dispatch(footerActions.setColor('black'))
  dispatch(headerActions.toggleHeader(true))

  return {}
}

export default IndexEgePage
