import { EProgramTypes } from '@ucheba/utils/types/program'
import { EEventsDataKeys, EEventsFacets } from '@ucheba/store/events/search/types'
import data from './data.json'
import { ISectionsNavigationItem } from './types'

export const successRegisterOfflineEventNoticeId = 'successRegisterOfflineEventNoticeId'
export const successRegisterOnlineNotStartedEventNoticeId =
  'successRegisterOnlineNotStartedEventNoticeId'
export const successRegisterOnlineStartedEventNoticeId =
  'successRegisterOnlineStartedEventNoticeId'

export const sectionsEventsNavigationItems: ISectionsNavigationItem[] = [
  {
    name: 'Все',
    slug: '-1',
  },
  {
    name: data.sectionsNavigation.higherName,
    slug: String(EProgramTypes.higher),
  },
  {
    name: data.sectionsNavigation.vocationalName,
    slug: String(EProgramTypes.vocational),
  },
]

export const eventsFacetBySearchQuery = {
  [EEventsDataKeys.formatsIds]: EEventsFacets.formats,
  [EEventsDataKeys.locationIds]: EEventsFacets.locations,
}

export const searchQueryByEventsFacet = {
  [EEventsFacets.tags]: EEventsDataKeys.tagIds,
  [EEventsFacets.formats]: EEventsDataKeys.formatsIds,
  [EEventsFacets.locations]: EEventsDataKeys.locationIds,
}

export const titleByEventsFacetName = {
  [EEventsFacets.formats]: 'Формат',
  [EEventsFacets.locations]: 'Город учебного заведения',
}

export const pathname = '/for-abiturients/events'
