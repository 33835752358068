import { IPropId, IPropName } from '@ucheba/utils/helpers/components/prop.types'
import { eventsSearchSlice } from './index'
import { IFacet } from '../../crm/institutions/id/leads/types'

export interface IEventsSearchState {
  [key: string]: ReturnType<typeof eventsSearchSlice.reducer>
}

export interface IEventsSearch {
  total: number
  items: IEventsSearchItem[]
  facets: IEventsFacets
}

export interface IEventsSearchItem extends Required<IPropId<number>>, IPropName {
  id: number
  name: string
  startAt: string
  endAt: string
  finishAt: string
  isFinished: true
  description: string
  streamUrl: string | null
  directVkStreamUrl: string | null
  externalUrl: string | null
  withExternalRegistration: boolean

  institution: {
    id: number
    name: string
    logo: string
    abbreviation: string
    location: {
      id: number
      name: string
    }
  }

  rubric: {
    id: number
    name: string
  }

  addresses: IAddress[]
  programTypes: IProgramType[]
  formats: IFormat[]
}

export enum EEventsFacets {
  formats = 'formats',
  locations = 'locations',
  tags = 'tags',
}

export enum EEventsDataKeys {
  query = 'query',
  formatsIds = 'formats',
  locationIds = 'locationIds',
  tagIds = 'tagIds',
}

export interface IEventsFacets {
  [EEventsFacets.formats]: IFacet[]
  [EEventsFacets.locations]: IFacet[]
  [EEventsFacets.tags]: IFacet[]
}

interface IFormat {
  value: string
  name: string
}

interface IProgramType {
  value: number
  name: string
}

interface IAddress {
  address: string
  comment: string | null
  point: {
    longitude: number
    latitude: number
  }
}
